var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import axios from "axios";
import { createContext, useCallback, useEffect, useRef, useState, } from "react";
import { Loading } from "../nav/Loading";
export var UserContext = createContext({
    user: null,
    loading: null,
    setUser: function () { return undefined; },
    setLoading: function () { return undefined; },
});
export var UserProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState(null), 2), user = _b[0], setUser = _b[1];
    var _c = __read(useState(true), 2), loading = _c[0], setLoading = _c[1];
    var timeoutRef = useRef(null);
    var isRefreshing = useRef(false);
    var isVerifying = useRef(false);
    var refresh = useCallback(function () {
        if (isRefreshing.current)
            return;
        isRefreshing.current = true;
        axios
            .post("/dj-rest-auth/token/refresh/")
            .then(function (response) {
            var accessExpiration = new Date(response.data.accessExpiration);
            var now = new Date();
            // Schedule next refresh 5 minutes before token expiration
            var refreshInterval = accessExpiration.getTime() - now.getTime() - 5 * 60 * 1000;
            if (refreshInterval <= 0) {
                refreshInterval = 1000; // Set to 1 second if already expired
            }
            timeoutRef.current = setTimeout(refresh, refreshInterval);
            // Fetch user details after successful token refresh
            if (!user) {
                axios
                    .get("/dj-rest-auth/user/")
                    .then(function (response) {
                    setUser(response.data);
                    setLoading(false);
                })
                    .catch(function () {
                    console.error("Failed to fetch user");
                    setUser(null);
                    setLoading(false);
                });
            }
        })
            .catch(function (error) {
            console.error("Token refresh failed", error);
            setUser(null);
            setLoading(false);
        })
            .finally(function () {
            isRefreshing.current = false; // Reset the flag
        });
    }, [user]);
    var verifyToken = useCallback(function () {
        if (isVerifying.current)
            return;
        isVerifying.current = true;
        return axios
            .post("/dj-rest-auth/token/verify/", {})
            .then(function () {
            if (!user) {
                return axios.get("/dj-rest-auth/user/").then(function (response) {
                    setUser(response.data);
                    setLoading(false);
                });
            }
        })
            .catch(function () {
            return refresh();
        })
            .finally(function () {
            isVerifying.current = false;
        });
    }, [user, refresh]);
    useEffect(function () {
        if (loading &&
            user === null &&
            !isRefreshing.current &&
            !isVerifying.current) {
            verifyToken();
        }
        return function () {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [loading, user, verifyToken]);
    if (loading) {
        return _jsx(Loading, {});
    }
    return (_jsx(UserContext.Provider, { value: { user: user, loading: loading, setUser: setUser, setLoading: setLoading }, children: children }));
};
