import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useRetrievePlayerByExternalUserId, useRetrieveTeamsByPlayerId, useUpdateExternalUser, } from "../ApiProvider";
import { ErrorView } from "../nav/Error";
import { Loading } from "../nav/Loading";
import ErrorSnackbar from "../utils/ErrorSnackbar";
import { useNumericParams } from "../utils/useNumericParams";
import { ExternalUserForm } from "./ExternalUserForm";
export var UpdateExternalUser = function () {
    var id = useNumericParams().id;
    var navigate = useNavigate();
    var _a = useRetrievePlayerByExternalUserId({ externalUserId: id }), player = _a.data, isLoading = _a.isLoading, error = _a.error;
    var _b = useRetrieveTeamsByPlayerId({ playerId: player === null || player === void 0 ? void 0 : player.id }), teams = _b.data, isTeamsLoading = _b.isLoading, teamsError = _b.error;
    var updateExternalUserMutation = useUpdateExternalUser({
        player: player,
        playerTeams: teams,
        onMutate: function () { },
        onSuccess: function (data) {
            navigate("/players/".concat(player.id, "/"));
            return data.data;
        },
        onError: function () { },
    });
    var onSubmit = function (player) {
        updateExternalUserMutation.mutate(player);
    };
    if (isLoading || updateExternalUserMutation.isLoading || isTeamsLoading) {
        return _jsx(Loading, {});
    }
    if (error || teamsError || !player || !teams) {
        return _jsx(ErrorView, { error: error });
    }
    return (_jsxs(React.Fragment, { children: [_jsx(ErrorSnackbar, { open: !!updateExternalUserMutation.isError, message: "Failed to update external user!" }), _jsx(ExternalUserForm, { externalUser: player.externalUser, onSubmit: onSubmit, onCancel: function () {
                    navigate(-1);
                } })] }));
};
