var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, FormLabel, IconButton, Typography } from "@mui/material";
import { useState } from "react";
export var FileUploadPreview = function (_a) {
    var onChange = _a.onChange, _b = _a.label, label = _b === void 0 ? "Upload File" : _b;
    var maxSizeInMB = 2.5;
    var _c = __read(useState(null), 2), preview = _c[0], setPreview = _c[1];
    var _d = __read(useState(null), 2), fileName = _d[0], setFileName = _d[1];
    var _e = __read(useState(null), 2), error = _e[0], setError = _e[1];
    var handleFileChange = function (e) {
        var _a;
        var file = ((_a = e.target.files) === null || _a === void 0 ? void 0 : _a[0]) || null;
        if (file) {
            var maxSizeInBytes = maxSizeInMB * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                setError("File size exceeds ".concat(maxSizeInMB, " MB limit."));
                setPreview(null);
                setFileName(null);
                onChange(undefined); // Clear the form value
                return;
            }
            var reader_1 = new FileReader();
            reader_1.onload = function () {
                setPreview(reader_1.result); // Base64 for preview
                setFileName(file.name);
                var base64String = reader_1.result;
                onChange(base64String);
                setError(null);
            };
            reader_1.readAsDataURL(file);
        }
        else {
            setPreview(null);
            setFileName(null);
            onChange(undefined);
            setError(null);
        }
    };
    var handleRemove = function () {
        setPreview(null);
        setFileName(null);
        onChange(undefined);
        setError(null);
    };
    return (_jsxs(Box, { mt: 2, position: "relative", children: [_jsx(FormLabel, { style: {
                    position: "absolute",
                    top: "-10px",
                    left: "12px",
                    backgroundColor: "white",
                    padding: "0 4px",
                    zIndex: 1,
                    fontSize: "0.75rem",
                }, children: label }), _jsx(Box, { display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", p: 2, border: "1px solid grey", borderRadius: 2, position: "relative", bgcolor: "background.paper", children: preview ? (_jsxs(_Fragment, { children: [_jsx("img", { src: preview, alt: "Preview", style: {
                                maxWidth: "150px",
                                maxHeight: "150px",
                                borderRadius: "8px",
                                marginBottom: "8px",
                            } }), _jsx(Typography, { variant: "body2", color: "textSecondary", children: fileName }), _jsx(IconButton, { onClick: handleRemove, color: "error", size: "small", style: { marginTop: "8px" }, children: _jsx(DeleteIcon, {}) })] })) : (_jsxs(_Fragment, { children: [_jsx(CloudUploadIcon, { fontSize: "large", color: "action" }), _jsx(Typography, { variant: "body2", color: "textSecondary", children: "Upload your logo (max file size: 5 MB)" }), _jsx("input", { type: "file", accept: "image/*", style: {
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                opacity: 0,
                                cursor: "pointer",
                            }, onChange: handleFileChange })] })) }), error && (_jsx(Typography, { variant: "body2", color: "error", style: { marginTop: "8px" }, children: error }))] }));
};
