import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, CardContent, Divider, Grid, Stack, Typography, } from "@mui/material";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { useListIntraSquadMatchesByPlayerId, useRetrievePlayerByUserId, useRetrieveTeamPreferences, useRetrieveTeamsByPlayerId, } from "./ApiProvider";
import Landing from "./Landing";
import { UserContext } from "./auth/UserProvider";
import { ErrorView } from "./nav/Error";
import { Loading } from "./nav/Loading";
import { PlayerView } from "./players/PlayerView";
import { FormGuide } from "./stats/FormGuide";
import { IntraSquadMatchResultListItemText } from "./stats/IntraSquadMatchResultListItemText";
import { aggregatePlayerIntraSquadMatchStats } from "./stats/utils";
import { TeamsCard } from "./teams/TeamsCard";
var Count = function (_a) {
    var word = _a.word, n = _a.n;
    return (_jsxs(Stack, { style: { textAlign: "center" }, spacing: 1, children: [_jsx(Typography, { variant: "caption", display: "block", gutterBottom: true, children: word }), _jsx(Typography, { variant: "h5", children: _jsx("strong", { children: n }) })] }));
};
var Home = function () {
    var _a;
    var _b = useContext(UserContext), contextUser = _b.user, isContextUserLoading = _b.loading;
    var _c = useRetrievePlayerByUserId({ userId: contextUser === null || contextUser === void 0 ? void 0 : contextUser.pk }), isPlayerLoading = _c.isLoading, playerError = _c.error, player = _c.data;
    var _d = useListIntraSquadMatchesByPlayerId({
        playerId: player === null || player === void 0 ? void 0 : player.id,
    }), isIntraSquadMatchesLoading = _d.isLoading, intraSquadMatchesError = _d.error, intraSquadMatches = _d.data;
    var _e = useRetrieveTeamsByPlayerId({
        playerId: player === null || player === void 0 ? void 0 : player.id,
    }), isTeamsLoading = _e.isLoading, teamsError = _e.error, teams = _e.data;
    var pastMatches = intraSquadMatches === null || intraSquadMatches === void 0 ? void 0 : intraSquadMatches.filter(function (match) { return new Date(match.kickOff) < new Date(); }).sort(function (a, b) { return new Date(b.kickOff).getTime() - new Date(a.kickOff).getTime(); });
    var recentActivity = pastMatches && pastMatches[0];
    var futureMatches = intraSquadMatches === null || intraSquadMatches === void 0 ? void 0 : intraSquadMatches.filter(function (match) { return new Date(match.kickOff) > new Date(); }).sort(function (a, b) { return new Date(a.kickOff).getTime() - new Date(b.kickOff).getTime(); });
    var nextActivity = futureMatches && futureMatches[0];
    var _f = useRetrieveTeamPreferences({ teamId: nextActivity === null || nextActivity === void 0 ? void 0 : nextActivity.team }), nextActivityTeamPreference = _f.data, nextActivityTeamPreferenceIsLoading = _f.isLoading, nextActivityTeamPreferenceIsError = _f.isError;
    var _g = useRetrieveTeamPreferences({ teamId: recentActivity === null || recentActivity === void 0 ? void 0 : recentActivity.team }), recentActivityTeamPreference = _g.data, recentActivityTeamPreferenceIsLoading = _g.isLoading, recentActivityTeamPreferenceIsError = _g.isError;
    var combinedError = playerError ||
        intraSquadMatchesError ||
        teamsError ||
        recentActivityTeamPreferenceIsError ||
        nextActivityTeamPreferenceIsError;
    var loading = isPlayerLoading ||
        isIntraSquadMatchesLoading ||
        isTeamsLoading ||
        (recentActivityTeamPreferenceIsLoading && recentActivity) ||
        (nextActivityTeamPreferenceIsLoading && nextActivity) ||
        isContextUserLoading;
    if (!contextUser && !isContextUserLoading) {
        return _jsx(Landing, {});
    }
    if (loading) {
        return _jsx(Loading, {});
    }
    if (combinedError) {
        return _jsx(ErrorView, { error: playerError });
    }
    if (teams === undefined ||
        player === undefined ||
        intraSquadMatches === undefined ||
        pastMatches === undefined ||
        futureMatches === undefined) {
        return (_jsx(ErrorView, { error: new Error("Failed to load user data correctly.") }));
    }
    var formatActivity = function (intraSquadMatch, teamPreference, label) {
        return intraSquadMatch && teamPreference ? (_jsx(React.Fragment, { children: _jsx(Link, { to: "/intra-squad-matches/".concat(intraSquadMatch.id), style: { textDecoration: "none" }, children: _jsx(IntraSquadMatchResultListItemText, { intraSquadMatch: intraSquadMatch, teamPreference: teamPreference }) }) })) : (_jsxs(React.Fragment, { children: ["No ", label, " activity"] }));
    };
    return (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, md: 4, lg: 4, children: _jsxs(Stack, { spacing: 2, children: [_jsx(Card, { children: _jsx(CardContent, { children: _jsx(PlayerView, { player: player, variant: "h6" }) }) }), _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "caption", align: "left", display: "block", children: "Latest Activity" }), _jsx(Typography, { variant: "body1", align: "left", display: "block", children: formatActivity(recentActivity, recentActivityTeamPreference, "recent") })] }) }), _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "caption", align: "left", display: "block", children: "Next Activity" }), _jsx(Typography, { variant: "body1", align: "left", display: "block", children: formatActivity(nextActivity, nextActivityTeamPreference, "upcoming") })] }) })] }) }), _jsx(Grid, { item: true, xs: 12, md: 5, lg: 5, children: _jsx(Card, { children: _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h6", align: "center", children: "Intra-Squad Matches" }), _jsx(Divider, {}), _jsx(Box, { sx: {
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "background.paper",
                                    color: "text.secondary",
                                    margin: 2,
                                }, children: _jsxs(Stack, { spacing: 2, direction: "row", alignItems: "center", children: [_jsx(Count, { word: "Goals", n: ((_a = aggregatePlayerIntraSquadMatchStats(intraSquadMatches)[player.id]) === null || _a === void 0 ? void 0 : _a.goalsScored) || 0 }), _jsx(Divider, { orientation: "vertical", flexItem: true }), _jsx(Count, { word: "Matches", n: intraSquadMatches.length }), _jsx(Divider, { orientation: "vertical", flexItem: true }), _jsxs(Stack, { spacing: 1, alignItems: "center", children: [_jsx(Typography, { variant: "caption", display: "block", gutterBottom: true, children: "Form Guide" }), _jsx(FormGuide, { matches: pastMatches, playerId: player.id })] })] }) })] }) }) }), _jsx(Grid, { item: true, xs: 12, md: 3, lg: 3, children: _jsx(TeamsCard, { teams: teams }) })] }));
};
export default Home;
